<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="filterTable"
              /> -->
              
              <div>
                <b-button
                  variant="warning"
                  class="ml-1"
                >
                  <span class="text-nowrap" @click="openModel(null)">Add Background</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #cell(sno)="items">           
            <b-form-input placeholder="" autocomplete="off" v-model="items.item.order" @change="updateOrder(items.item)" @keypress="isNumber($event)" class="text-center"/>
        </template>

        <template #cell(image)="items">
            
            <img :src="items.item.image" v-if="items.item.image.split('.').pop() != 'mp4'" style="width:100px" />

            <b-embed type="video" aspect="1by1" :src="items.item.image" v-if="items.item.image.split('.').pop() == 'mp4'" style="width: 100px" />

        </template>

        <template #cell(status)="items">           
            <b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize">
              {{ items.item.status}}
            </b-badge>
        </template>

        <template #cell(actions)="items">
          <!-- <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" >

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item :to="{}" @click="openModel(items.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item  @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>  -->

            <b-link :to="{}" @click="openModel(items.item)"
              v-b-tooltip.hover.v-warning
              title="Edit"
              variant="outline-warning"
            >
                <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
                
            </b-link>
            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>

           <b-link  @click="deleteItem(items.item._id)"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


    <b-modal
      id="background-model"
      ref="background-model"
      cancel-variant="outline-secondary"
      ok-variant="outline-warning"
      ok-title="Submit"
      cancel-title="Close"
      centered
      :title="title"
      @ok="updateFile"
      no-close-on-backdrop
      size="lg"
    >

      <b-alert
      v-model="popshowDismissibleAlert"
      variant="danger"
      dismissible
      class="mb-1"
    >
      <div class="alert-body">
        {{error_message}}
      </div>
    </b-alert>

      <b-form>

        <b-row class="mt-2">
          <b-col md="12">

            <b-form-group
              label="Upload Image ( accept only .jpg, .png, .jpeg )"
              class="mb-1  required">
              <b-form-file
                id="extension"
                ref="file-input"
                accept=".png, .jpg, .jpeg"
                @change="fileUpdate"
              />
            </b-form-group>
            
            <p class="mt-2" v-if="form.image.image != null">
              <img :src="form.image.image" style="width:200px"></img>
            </p>

          </b-col>
        </b-row>
        
      </b-form>
  </b-modal>



  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination, BBreadcrumb,BEmbed,BForm,BAlert,BFormFile,BModal,BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BBreadcrumb,
    BEmbed,
    BForm,
    BAlert,
    BFormFile,
    BModal,
    BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
       tableColumns: [
            { key: 'sno', label: 'S.NO', sortable: false , thStyle:  {width: '12%'}},
            { key: 'image', label: 'Background Image', sortable: false , thStyle:  {width: '45%'}},
            { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '20%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '23%'}},
          ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'order',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        title:'Add Background Image',
        form:{

          id:null,
          image : {
            name:'',
            image:null
          }
        },
        popshowDismissibleAlert:null,
        error_message:'',

    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                   },
                   api: '/api/master-background-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Background Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Background Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-master-background-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Background Image Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-master-background-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },
    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:null},
        text: 'Feedback Panel',
        active:false
      },{
        to:{name:null},
        text: 'Background',
        active:true
      }];
      return item;
    },
    openModel(item){

      this.popshowDismissibleAlert = false;

      this.title = item == null ? 'Add Background Image' : 'Update Background Image';

      this.form = {
        id : item ? item._id : null,
        image : {
          name:'',
          image: item ? item.image + '?' + new Date() : null
        }
      }

      this.$refs['background-model'].show();
    },
    updateFile(e){
      e.preventDefault()

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-master-background'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.popshowDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: this.form.id == null ? 'Record Added Successfully.' : 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$refs['background-model'].hide();
                    this.filterTable();
                });
                
            }
        });
    },
    fileUpdate(event){
      var input = event.target;
      var files = event.target.files
      //console.log(files);
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['file-input'].reset()

          } else {

              reader.onload = (e) => {
                  this.form.image.image = e.target.result;
                  this.form.image.name = image.name;

                  //this.form.temp = '';
            }
          }
      }
    },
    isNumber($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        // only allow number 
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }
    },
    updateOrder(item){
        return this.$store.dispatch(POST_API, {
            data:{
                id     : item._id,
                order  : item.order
                },
            api:"/api/master-background-order",
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                //this.showAlert();
            } else {
                
                this.$refs.refUserListTable.refresh();
                
            }
        });

    },
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
